@import '../../../../scss/values';

.additional-wheel-timer-progress {
  @media screen and (max-width: $tablet-max-width-trigger) {
    &.desktop {
      display: none !important;
    }
  }

  @media screen and (min-width: $desktop-min-width-trigger) {
    &.mobile {
      display: none !important;
    }
  }
}
